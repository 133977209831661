$primary: #1C2F54 !default;
$secondary: #2878CD !default;

@import '../../../../src/scss/main.scss';

@import url("https://use.typekit.net/kdk2hyq.css");

* {
  font-family: "poppins", sans-serif;
}
